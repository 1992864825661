// tag manager
window.dataLayer = window.dataLayer || []; 
function gtag() { window.dataLayer.push(arguments); } 
gtag('consent', 'default', { 
  ad_storage: 'denied', 
  analytics_storage: 'denied', 
wait_for_update: 500 
}); 

function cookiesAnalytics() { 
 // console.log('analytics');
  dataLayer.push({'event':'cookies_analytics'}); 
  gtag('consent', 'update', { analytics_storage: 'granted' }); 
} 

function cookiesMarketing() {
 // console.log('marketing');
 // fbq('consent', 'grant');
  dataLayer.push({'event':'cookies_marketing'});
  gtag('consent', 'update', { ad_storage: 'granted' });
}  
/*
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': 
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], 
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); 
})(window,document,'script','dataLayer','GTM-M7MZ2QF');*/
