$(document).ready(function() {

  'use strict';

  // -----------
  // Mobile menu
  // -----------

  var handleMatchMedia = function (mediaQuery) {
    // ≤ 720px
    if (mediaQuery.matches) {
      // crear el botó del menú
      // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
      // ocultar el menú
      $('.menu-wrapper').attr('hidden','').addClass('js-hidden');
      // clicar el botó
      $('.main-nav button').on('click', function() {
        // afegir classe al botó
        $(this).toggleClass('is-active');
        // afegir classe al body
        $('body').toggleClass('js-menu-open');
        // canviar atributs del botó i del menú
        if ($(this).attr('aria-expanded') == 'true') {
          $(this).attr('aria-expanded', 'false');
          $('.menu-wrapper').attr('hidden','').addClass('js-hidden');
        }
        else {
          $(this).attr('aria-expanded', 'true');
          $('.menu-wrapper').removeAttr('hidden').removeClass('js-hidden');
        }
      });
    }
    // ≥ 721px
    else {
      // treure el botó
      // $('.main-nav button').remove();
      // mostrar el menú
      $('.menu-wrapper').removeAttr('hidden').removeClass('js-hidden');
    }
  },
  mq = window.matchMedia('only screen and (max-width: 720px)');
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);



  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $('html').addClass('svg');
  }



  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event){

      // strip the leading hash and declare
      // the content we're skipping to
      var skipTo="#"+this.href.split('#')[1];

      // Setting 'tabindex' to -1 takes an element out of normal
      // tab flow but allows it to be focused via javascript
      $(skipTo).attr('tabindex', -1).on('blur focusout', function () {

          // when focus leaves this element,
          // remove the tabindex attribute
          $(this).removeAttr('tabindex');

      }).focus(); // focus on the content container
  });



  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName('img');
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
        if (imgs[i].src.match(endsWithDotSvg)) {
            imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
        }
    }
  }



  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia('(min-width: 721px)').matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    // var headerHeight = 82; // fixed header height
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#menu"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
              // if (window.matchMedia("(min-width: 64.0625em)").matches) {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top - headerHeight
              //       }, 1000);
              // } else {
              //       $('html, body').animate({
              //         scrollTop: target.offset().top
              //       }, 1000);
              // }
              $('html, body').animate({
                scrollTop: target.offset().top
              }, 1000);
            return false;
          }
        }
      });
    });
  }



  // -------------
  // Sticky header
  // -------------

  // var handleMatchMedia2 = function (mediaQuery) {
  //   // ≥ 1024px
  //   if (mediaQuery.matches) {
  //     var lastScrollTop = 115;
  //     $(window).scroll(function(event){
  //         var st = $(this).scrollTop();
  //         if (st > lastScrollTop){
  //             $('.main-header-sub').addClass('sticky');
  //             $('body').addClass('sticky-header');
  //         } else {
  //             $('.main-header-sub').removeClass('sticky');
  //             $('body').removeClass('sticky-header');
  //         }
  //         // lastScrollTop = st;
  //     });
  //   }
  // },
  // mq2 = window.matchMedia('only screen and (min-width: 1024px)');
  // handleMatchMedia2(mq2);
  // mq2.addListener(handleMatchMedia2);



  // ------------
  // Match height
  // ------------

  $('.h-dest').matchHeight();

 // ------------
  // acf-slide 
  // ------------
 


$('.acf-slider').slick( 
    {       
    dots: false,       
    speed: 300,        
    arrows: true,     
    infinite: true,  
    slidesToShow: 1,       
    autoplay: true,       
    autoplaySpeed: 8000,       
   // nextArrow: '<i class="fa fa-arrow-right"></i>',        
   // prevArrow: '<i class="fa fa-arrow-left"></i>',      
        
    responsive: [{ 
        breakpoint: 1024,           
        settings:  {               
            slidesToShow: 1,               
            infinite: true  
            }       
        }, 
        {       
        breakpoint: 600,           
        settings: {               
            slidesToShow: 1,               
            arrows: false           
        }       
    }]   
     
})



  // ------------
  // Imatges home
  // ------------

  /*$('.intro--controls').on('mouseover', 'li', function () {
    $('.h-imgs__list img').removeClass('opaque');

    var newImage = $(this).index();

    $('.h-imgs__list img').eq(newImage).addClass('opaque');

    $('.intro--controls li').removeClass('selected');
    $(this).addClass('selected');
  });*/

  $('#show-faq').on('click', function( event ) {
        event.preventDefault();
        $(this).toggleClass('is-active');
        $('.wrapper-faq').toggleClass('open');

  });
  if ($('#scrolling').length > 0) {
    var carousel;
    carousel = $("#scrolling ul");
    carousel.itemslide({
        swipe_out: false ,
        left_sided: true,
        disable_clicktoslide: true,
    }); //initialize itemslide
    $(window).resize(function () {
        carousel.reload();
    });
    var num = carousel.find("li").length;
    var next = 5;
    if (num < 5){
        next = num;
    }
    var activa = 0;
    var prev = 0;


    var isDragging = false;
    $("#scrolling ul a")
    .mousedown(function() {
        isDragging = false;
    })
    .mousemove(function() {
        isDragging = true;
     })
    $("#scrolling ul a").on("click", function(event) {
      if(isDragging){
        event.preventDefault();
      }
    });

    $('#next').on('click', function( event ) {
          event.preventDefault();
          activa = carousel.getActiveIndex();
          $('#previous').fadeIn();
          if (activa == 0){
            next = 5;
            if (num < 5){
              next = num;
            }
          }
          if (activa < num){
            carousel.gotoSlide(next);
            activa = carousel.getActiveIndex();
            next = activa + 3;
            prev = activa - 3;
            if (next > num){
              next = num;
            }
          }
          if (activa == num-1){
            $('#next').fadeOut();
          }


    });
    $('#previous').on('click', function( event ) {
          event.preventDefault();
          activa = carousel.getActiveIndex();
          $('#next').show();
          if (activa == 0){
            activa = 2;
            $('#previous').fadeOut();
          }

          if (activa > 0){
            carousel.gotoSlide(prev);
            activa = carousel.getActiveIndex();
            //console.log("new active: "+ carousel.getActiveIndex());
            prev = activa - 3;
            next = activa + 3;
            if (activa < 4){
              $('#previous').fadeOut();
            }
          }

    });
  }



  $('img.product-img')
    .wrap('<span style="display:inline-block"></span>')
    .css('display', 'block')
    .parent()
    .zoom();


    // ------------
    // missatge de producte d'una mateixa categoria
    // ------------
    
    if($("#dialog").length) {

      $("#dialog").insertAfter('.main-content .row h1');

      if ($('.single-product').length) {
        $("#dialog").css('margin-top','30px');					 
      } 

      $("#dialog").css('display','block');
    }


    // ------------
    // Opció particular empresa woocommerce
    // ------------

   
    //amagar camps enviament diferent
    $('.shipping_address').hide();

    $('body').on('change', 'input[name=ship_to_different_address]', function(){

      var valor_ship = $('#ship-to-different-address-checkbox').val();


      if ($('#ship-to-different-address-checkbox').is(":checked")) $('.shipping_address').show();
      else $('.shipping_address').hide();				

    });

    //var wrapper = $(this).closest('.form-row');
    actionRequire('#billing_first_name_field');
    actionRequire('#billing_last_name_field');
    actionRequire('#billing_birthday_field');
    actionRequire('#billing_company_field');
    actionRequire('#billing_nif_field');
    actionRequire('#billing_dni_nie_field');




    function actionRequire(selector) {   
      $(selector).addClass('validate-required');
      $(selector+' label span').remove();
      $(selector+' label').append('<abbr class="required" title="obligatorio">*</abbr>');
      $(selector).removeClass('woocommerce-validated');
      $(selector).removeClass('woocommerce-invalid woocommerce-invalid-required-field');
    }
  
    show_camps();

    $('body').on('change', 'input[name=billing_particular_empresa]', function(){							
      show_camps();
    });	

    $('body').on('change', 'input[name=billing_company]', function(){							
      $('#billing_first_name_field').find('input').val('');
      $('#billing_last_name_field').find('input').val('');
      $('#billing_birthday_field').find('input').val('');
      $('#billing_dni_nie_field').find('input').val('');
    });	

    $('body').on('change', 'input[name=billing_first_name]', function(){							
      $('#billing_company_field').find('input').val('');
      $('#billing_nif_field').find('input').val('');
    });	
    

  
  function show_camps(){

    var valor = $('#billing_particular_empresa_field').find('input:checked').val();

    $('#billing_company_field').hide();


    if(valor == 'empresa' ) { 

      $('#billing_first_name_field').hide();
      $('#billing_last_name_field').hide();
      $('#billing_birthday_field').hide();
      $('#billing_dni_nie_field').hide(); 				

      $('#billing_company_field').show();
      $('#billing_nif_field').show();

    } else {

      $('#billing_first_name_field').show();
      $('#billing_last_name_field').show();
      $('#billing_birthday_field').show();
      $('#billing_dni_nie_field').show();

      $('#billing_company_field').hide();
      $('#billing_nif_field').hide();

    }
  }



});

